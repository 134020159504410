import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import swal, { SweetAlertResult } from 'sweetalert2';

export enum S1PopUpType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
  Question = "question"
}

@Injectable({
  providedIn: 'root'
})
export class S1UIService {

  constructor(private translate: TranslateService, private toasterService: ToastrService) { 
    
  }

  showBlockingSpinner() {
    if(!swal.isVisible()) {
      swal.fire({
        title: this.translate.instant('s1.swal.loading'),
        allowOutsideClick: false,
        html: '<strong class="text-primary"></strong>',
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          swal.showLoading();
        }
      });
    }
  }
  
  updateFooter(text) {
    if(swal.isVisible()) {
      swal.update({
        html: '<strong class="text-primary">'+text+'</strong>'
      });
    }
  }
  
  // Spinner
  showSpinner() {
    if(!swal.isVisible()) {
      swal.fire({
        title: this.translate.instant('s1.swal.loading'),
        didOpen: () => {
          swal.showLoading();
        }
      });
    }
  }

  closeSpinner() {
    swal.close();
  }

  // Popup
  showPopup(type: S1PopUpType, title?: string, text?: string) {

    swal.fire(this.translate.instant(title),this.translate.instant(text), type);

  }

  showErrorPopup(errorMessage: string) {
    this.showPopup(S1PopUpType.Error, this.translate.instant('s1.swal.error'), this.translate.instant(errorMessage))
  }

  showPopupNoPermission() {
    this.showPopup(S1PopUpType.Warning, 's1.swal.attention',  's1.swal.noPermission')
  }

  showHTTPErrorPopup(error: any) {
    
    let errorTitle = 's1.swal.error'
    let errorMessage = 'genericError'

    if (error.statusText) {
      errorMessage = error.statusText;
    }

    if (error.status) {
      errorTitle = "" + error.status
      switch(error.status) {
        case 25: //ipaddress not coherent
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_not_coherent')
          break;
        case 26: //ipaddress to > from
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_from_to_error')
          break;
        case 406:
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.too_many_results')
          break;
        case 401: // Unauthorized
          errorMessage = this.translate.instant('APIErrors.' + error.status)
          break;
        case 403: // Forbidden
          swal.close();
          return;
      }
    }

    this.showPopup(S1PopUpType.Error, errorTitle, errorMessage);

  }

  showDialogPopup(title: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
    })

  }

  showDialogWithInputTextPopup(inputMessage: string, flagMessage: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      icon: S1PopUpType.Question,
      html: `<label><input type="checkbox" id="flag" class="mr-2 mb-4">`+ this.translate.instant(flagMessage) +`</label>
             <label>`+ this.translate.instant(inputMessage) +`</label><input type="text" id="content" class="swal2-input" maxlength="255">`,
      preConfirm: () => {
        const content: any = swal.getPopup().querySelector('#content');
        const flag: any = swal.getPopup().querySelector('#flag');
        return { 'text': content.value, 'flag': flag.checked };
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
      
    })

  }

  showDialogWithCheckPopup(title: string, options: any = {}): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      inputValue: 'no',
      input: 'radio',
      inputOptions: options,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose something!'
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
    })

  }

  showDialogWithCheckBoxPopup(title: string, option: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      inputValue: null,
      input: 'checkbox',
      inputPlaceholder: option,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
    })

  }

  // TOAST
  showSuccessToast(text: string) {
    this.toasterService.success(this.translate.instant(text), this.translate.instant("s1.toast.success"));
  }

  showErrorToast(text: string) {
    this.toasterService.error(this.translate.instant(text), this.translate.instant("s1.toast.error"));
  }

}
