import { Routes, CanActivate } from '@angular/router';
import { RoleGuardService as RoleGuard } from '../core/services/role-guard.service';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { ForgotComponent } from './pages/forgot/forgot.component';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'checks', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./checks/checks.module').then(m => m.ChecksModule) },
            { path: 'userprofile', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
            { path: 'user', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), data: { roles: ['ADMIN','SUPERADMIN']}, canActivate: [RoleGuard] },
            { path: 'checks', loadChildren: () => import('./checks/checks.module').then(m => m.ChecksModule) },
            { path: 'commesse', loadChildren: () => import('./commesse/commesse.module').then(m => m.CommesseModule) },
            { path: 'audits', loadChildren: () => import('./audits/audits.module').then(m => m.AuditsModule) },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent },
    { path: 'forgot', component: ForgotComponent },
    { path: 'recover/:token', component: RecoverComponent },
    { path: 'renew/:token', component: RecoverComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

    // Not found
    { path: '**', redirectTo: '404' }

];
