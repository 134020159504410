import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TranslatorService } from '@app/core/translator/translator.service';
import { S1ButtonType } from '@app/s1';
import { TranslateService } from '@ngx-translate/core';
import { CommandType, DeviceType, DrawCommand, DrawStyle } from 'ng-opendraw';
import { FileUploader } from 'ng2-file-upload';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'picture-management',
  templateUrl: './picture-management.component.html',
  styleUrls: ['./picture-management.component.scss']
})
export class PictureManagementComponent implements OnInit, OnDestroy {

  @Input() index: number = 1;
  @Input() readonly: boolean = false;
  @Input() set picture(src: string) {
    this.reset();
    this.pictureSrcOriginal = src;
    this.pictureSrc = this.pictureSrcOriginal;
  };
  @Output() onExport  = new EventEmitter<any>();
  
  pictureSrc: string;
  pictureSrcOriginal: string;
  pictureImage: HTMLImageElement = null;
  pictureAction: string = 'draw';
  pictureSelectionDrawStyle = this.translate.instant('base.line');
  pictureDrawStyle: DrawStyle = DrawStyle.LINE;
  pictureLineWidth = 3;
  pictureSelectionColor = 'text-dark';
  pictureLineColor = 'black';
  pictureEraser: boolean = false;
  pictureDeletable: boolean = false;
  pictureUpdatable: boolean = false;
  pictureCmd$ = new Subject<any>();
  pictureCropped: any = '';

  uploader: FileUploader  = new FileUploader({allowedFileType: ['image'], url: null});
  exeecedSizeError = false;
  imageFormatError = false;
  maxSize = 100*1024*1024;
  
  drawStyle = DrawStyle;
  drawStyleOptions: any;
  allowedDeviceType: DeviceType = DeviceType.ALL;
  s1ButtonType = S1ButtonType;
  changeLangEventSubscription : Subscription;
  exportingPicture = false;

  constructor(public translate: TranslateService, public translator: TranslatorService) {

    this.uploader.onWhenAddingFileFailed = (item) => {
      this.imageFormatError = true;
    }
    this.uploader.queue = [];
  }

  reset() {
    this.pictureImage = null;
    this.pictureAction = 'draw';
    this.pictureSelectionDrawStyle = this.translate.instant('base.line');
    this.pictureDrawStyle = DrawStyle.LINE;
    this.pictureLineWidth = 3;
    this.pictureSelectionColor = 'text-dark';
    this.pictureLineColor = 'black';
    this.pictureEraser = false;
    this.pictureDeletable = false;
    this.pictureUpdatable = false;
    this.pictureCmd$ = new Subject<any>();
    this.pictureCropped = '';
    this.uploader = new FileUploader({allowedFileType: ['image'], url: null},);
    this.exeecedSizeError = false;
    this.imageFormatError = false;
  }

  ngOnInit(): void {

    this.changeLangEventSubscription = this.translator.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.drawStyleOptions = [
          {"code": DrawStyle.LINE, "label": this.translate.instant('base.line')},
          {"code": DrawStyle.ELLIPSE, "label": this.translate.instant('base.circle')},
        ];
      }, 1)
    });

  }

  loadBackgroundImage(img: HTMLImageElement, source: string, hide: boolean = true): HTMLImageElement{
    img.style.display = 'block';
    let width = img?.width;
    let height = img?.height;
    if(hide) {
      img.style.display = 'none';
    }
    return this.getImage(source, width, height);
  }

  getImage(preview, width, height) {
    if(preview) {
      const img = new Image();
      img.width = width;
      img.height = height;
      img.crossOrigin="anonymous"
      img.src = preview;
      return img;
    }
    return null;
  }
  
  switchAction(action, param?): string {
    if(action == 'crop') {
      this.pictureCmd$.next(new DrawCommand(CommandType.EXPORT));
    } else if(action == 'draw') {
      return param;
    }
  }

  updatePicture() {
    this.pictureCmd$.next(new DrawCommand(CommandType.EXPORT));
  }

  exportPicture() {
    this.exportingPicture = true;
    if(!this.pictureDeletable && this.pictureSrc) {
      if(this.pictureAction == 'draw') {
        this.pictureCmd$.next(new DrawCommand(CommandType.EXPORT));
      } else {
        //action == crop
        this.onExport.emit(this.pictureSrc);
      }
    } else {
      //pictureDeletable == true or not present
      this.onExport.emit(null);
    }
    this.exportingPicture  = false;
  }

  processResult(result: string): string {
    if(this.exportingPicture) {
      this.onExport.emit(result);
      this.exportingPicture  = false;
    }
    return result;
  }
  
  imageCropped(event: ImageCroppedEvent): string {
    return event.base64;
  }

  changeColor(color: string) {
    this.pictureSelectionColor = color;
    switch (color) {
      case 'text-dark': this.pictureLineColor = 'black'; break;
      case 'text-white': this.pictureLineColor = 'white'; break;
      case 'text-danger': this.pictureLineColor = 'red'; break;
      case 'text-yellow': this.pictureLineColor = 'yellow'; break;
    }
  }
  
  changeDrawLine(drawLine: any) {
    if(drawLine == 'eraser') {
      this.pictureLineWidth = 12;
      this.pictureEraser = true;
      this.pictureDrawStyle = DrawStyle.NORMAL;
    } else {
      this.pictureLineWidth = 3;
      this.pictureEraser = false;
      this.pictureDrawStyle = drawLine;
    }
    switch (drawLine) {
      case DrawStyle.LINE: this.pictureSelectionDrawStyle = this.translate.instant('base.line'); break;
      case DrawStyle.ELLIPSE: this.pictureSelectionDrawStyle = this.translate.instant('base.circle'); break;
      case 'eraser': this.pictureSelectionDrawStyle = this.translate.instant('base.eraser'); break;
    }
  }

  resetPicture() {
    this.pictureSrc = null;
    this.pictureImage = null;
    setTimeout(() => { //trigger fragment reloading
      this.pictureSrc = this.pictureSrcOriginal;
    },1);
  }

  fileSelected() {
    let fileSelected = this.uploader?.queue[this.uploader?.queue.length -1];
    if(fileSelected) {
      this.uploader.queue = [fileSelected];
      this.exeecedSizeError = fileSelected?.file ? fileSelected.file?.size > this.maxSize : false;
      this.imageFormatError = false;
    }
  }

  fileChanged(event:any):void{ 
    if(!this.imageFormatError) {
      let file: File = event.target.files[0];
      let myReader: FileReader = new FileReader();
      let that = this;
      myReader.onloadend = function(loadEvent: any) {
          that.pictureSrc = loadEvent.target.result;
          that.pictureUpdatable = false;
      };
      myReader.readAsDataURL(file);
    }
    if(event.srcElement) {
      event.srcElement.value = ''; 
    }
  }

  processError(error) {
    console.log(error);
  }
  
  ngOnDestroy() {
    this.changeLangEventSubscription?.unsubscribe();
  }
}