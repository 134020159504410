import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { S1UIService } from '@app/s1';
import { LoggingService } from '@app/core/services/log.service';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
    
    valForm: UntypedFormGroup;
    passwordForm: UntypedFormGroup;
    tokenParm: string;
    renewPasswordPath = 'renew';
    isRenewPath: boolean = false;
    safeContext = false;
    
    constructor(public settings: SettingsService, fb: UntypedFormBuilder, private logger: LoggingService, private router: Router, private route: ActivatedRoute, private authService: AuthService, private ui: S1UIService) {

        let password = new UntypedFormControl('', Validators.required);
        let certainPassword = new UntypedFormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.route.params.subscribe(params => {
            this.tokenParm= params['token'];                  
        });

        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });

        this.valForm = fb.group({
            'passwordGroup': this.passwordForm
        });

        if(this.route.snapshot?.url?.length > 0) {
            this.isRenewPath = this.route.snapshot.url[0].path == this.renewPasswordPath;
        }
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {

            const params = {
                password: this.passwordForm.controls.password.value,
                firstLoginToken: this.tokenParm
            }

            this.ui.showSpinner();

            let observable = !this.isRenewPath ? this.authService.activate(params) : this.authService.renew(params);
            observable.subscribe(response => {
                this.ui.closeSpinner();

                if (response?.outcome?.success === true) {
                    this.ui.showSuccessToast('base.set_password_ok');
                    this.router.navigate(['login']);
                } else {
                    this.ui.showErrorToast(this.settings.manageErrorMsg(response.outcome));
                }
            },
            (error) => {
              this.logger.log("Error", error, 200);
              this.ui.closeSpinner();
              this.ui.showErrorToast('error.generic_error');
            });

        }
    }

    ngOnInit() {
    }

}
